import React from 'react'
import { FormattedMessage, defineMessages } from "react-intl";
import moment from 'moment';

export const translations = defineMessages({
    "statusPlaceholder": { id: "exports.status.placeholder", defaultMessage: "select status" },
    "recipientsPlaceholder": { id: "exports.recipients.placeholder", defaultMessage: "add an email" },
    "frequencyPlaceholder": { id: "exports.frequency.placeholder", defaultMessage: "select frequency" },
    "active": { id: "exports.status.active", defaultMessage: "active" },
    "inactive": { id: "exports.status.inactive", defaultMessage: "inactive" },
    "view": { id: "exports.actions.view", defaultMessage: "View" },
    "creationSuccess": { id: "exports.creation.toast.success", defaultMessage: "export configuration successfully created" },
    "creationError": { id: "exports.creation.toast.error", defaultMessage: "Oups... something went wrong while creating the export configuration. Please retry" },
    "updateSuccess": { id: "exports.update.toast.success", defaultMessage: "export configuration successfully updated" },
    "updateError": { id: "exports.update.toast.error", defaultMessage: "Oups... something went wrong while updating the export configuration. Please retry" },
    "deletionSuccess": { id: "exports.delete.toast.success", defaultMessage: "export configuration successfully deleted" },
    "deletionError": { id: "exports.delete.toast.error", defaultMessage: "Oups... something went wrong while deleting the export configuration. Please retry" },
});

export const frequencyOptions = [
    {
        value: "daily",
        label: <FormattedMessage id="exportsForm.frequency.daily" defaultMessage="Daily (everyday at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.daily.small" defaultMessage="Daily" />
    },
    {
        value: "weekly",
        label: <FormattedMessage id="exportsForm.frequency.weekly" defaultMessage="Weekly (every monday at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.weekly.small" defaultMessage="Weekly" />
    },
    {
        value: "monthly",
        label: <FormattedMessage id="exportsForm.frequency.monthly" defaultMessage="Monthly (every first day of the month at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.monthly.small" defaultMessage="Monthly" />
    },
]

export const statusOptions = [
    {
        value: "active",
        label: <FormattedMessage id="exportsForm.status.active" defaultMessage="active" />,
        color: "success",
        icon: "check"
    },
    {
        value: "inactive",
        label: <FormattedMessage id="exportsForm.status.inactive" defaultMessage="inactive" />,
        color: "danger",
        icon: "circle-xmark"
    },
]

export const getNextExec = (frequency) => {

    if (!frequency) return null

    const dateCopy = new Date()
    let result;
    // get next Monday
    if (frequency === "weekly") {
        //https://bobbyhadz.com/blog/javascript-get-date-of-next-friday#:~:text=To%20get%20the%20next%20Friday%2C%20we%3A&text=If%20the%20remainder%20is%20equal,month%20for%20the%20next%20Friday.
        result = new Date(
            dateCopy.setDate(
                dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
            ),
        );
    }
    // get first day of next month
    if (frequency === "monthly") {
        result = new Date(dateCopy.getFullYear(), dateCopy.getMonth() + 1, 1);
    }
    // get next day
    if (frequency === "daily") {
        result = new Date(dateCopy.setDate(dateCopy.getDate() + 1))
    }
    return {
        timestamp: Date.parse(result), //timestamp
        formattedLL: moment(result).format("LL"),
        formattedLocaleDateString: result?.toLocaleDateString("fr")
    }
}
