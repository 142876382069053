import { BASE_URL } from "./constants";


// Aircall endpoint that we need to redirect this request to:
let AircallAuthorizeUrl = 'https://dashboard-v2.aircall.io/oauth/authorize';
if(process.env?.REACT_APP_AIRCALL_AUTHORIZE_URL){
    AircallAuthorizeUrl = process.env.REACT_APP_AIRCALL_AUTHORIZE_URL;
}

// client_id given by Aircall when you registered your app:
// let AircallClientId = '3AoNuDR0wDDpBh5Zlqkfhc31MmeepKp9oxpXjE54LQw';
let AircallClientId = 'Ej4hv7EkTJ0U6ho1MvZ_TT8FwHK2IfQ1qhYmQ3OwlhU';
if(process.env?.REACT_APP_AIRCALL_CLIENT_ID){
    AircallClientId = process.env.REACT_APP_AIRCALL_CLIENT_ID;
}

// let AircallClientSecret = 'BnmwjQFqHWp3eDINskCSzp1_pVhmBf7oj0RuiDbkt10';
let AircallClientSecret = 'd0iPgd-lLpJqKzKwH5EV_XnOnafZdcRG-WpzOK-91P0';
if(process.env?.REACT_APP_AIRCALL_CLIENT_SECRET){
    AircallClientSecret = process.env.REACT_APP_AIRCALL_CLIENT_SECRET;
}

// Callback endpoint given to Aircall when registering your app (see next step)
// let AircallCallbackUrl = 'https://preprod.reecall.co/aircall/callback';
let AircallCallbackUrl = `${BASE_URL}/aircall/callback`;
if(process.env?.REACT_APP_AIRCALL_CALLBACK_URL){
    AircallCallbackUrl = process.env.REACT_APP_AIRCALL_CALLBACK_URL;
}

export {AircallAuthorizeUrl, AircallClientId, AircallClientSecret, AircallCallbackUrl}