import {
    FETCH_COMPANY_EXPORTS_TEMPLATE,
    FLUSH_EXPORT_STATUS,
    CREATE_EXPORT_CONFIGURATION,
    FLUSH_EXPORTS_STATUS,
    UPDATE_EXPORT_CONFIGURATION,
    DELETE_EXPORT_CONFIGURATION,
} from '../../actions/export';

const initialState = {
    exports: [],
    status: null,
    exportStatus: null,
    exportConfigurationCreationStatus: null,
    exportConfigurationUpdateStatus: null,
    exportConfigurationDeletionStatus: null,
    exportConfiguration: null,
}

const exportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FLUSH_EXPORT_STATUS:
            return {
                ...state,
                exportStatus: null
            }
        case FETCH_COMPANY_EXPORTS_TEMPLATE:
            return {
                ...state,
                exports: action.status === "success" ? action.config : state.exports,
                status: action.status
            }
        case CREATE_EXPORT_CONFIGURATION:
            return {
                ...state,
                exportConfigurationCreationStatus: action.status,
                exportConfiguration: action.exportConfiguration,
                exports: action.status === "success" ? [action.exportConfiguration, ...state.exports] : state.exports,
            }
        case UPDATE_EXPORT_CONFIGURATION:
            return {
                ...state,
                exportConfigurationUpdateStatus: action.status,
                exports: action.status === "success" ? [...state.exports.filter(el => el.id !== action.exportConfiguration.id), action.exportConfiguration] : state.exports,
            }
        case DELETE_EXPORT_CONFIGURATION:
            return {
                ...state,
                exports: action.status === "success" ? state.exports.filter(el => el.id !== action.id) : state.exports,
                exportConfigurationDeletionStatus: action.status
            }
        case FLUSH_EXPORTS_STATUS:
            return {
                ...state,
                exportConfigurationCreationStatus: null,
                exportConfigurationUpdateStatus: null,
                exportConfigurationDeletionStatus: null,
                exportConfiguration: null
            }
        default:
            return state;
    }
}
export default exportsReducer;