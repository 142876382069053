import axios from "axios";
import {AircallClientId, AircallClientSecret, AircallCallbackUrl} from "../../../configs/aircallConfig"
import { PHONE_MANAGMENT_URL } from "../../../configs/constants"
export const AIRCALL_SIGNUP_SET_STEP = "AIRCALL_SIGNUP_SET_STEP";
export const AIRCALL_SET_ACCESS_TOKEN = "AIRCALL_SET_ACCESS_TOKEN";
export const AIRCALL_LOGIN_SUCCESS = "AIRCALL_LOGIN_SUCCESS";
export const AIRCALL_SET_FORM_DATA = "AIRCALL_SET_FORM_DATA";
export const SET_SIGNUP_AGENT = "SET_SIGNUP_AGENT";

export const retrieveAccessToken = (oauthCode) => {
    return (dispatch, getState, {rcsdk}) => {
        const body = {
            'code': oauthCode,
            'redirect_uri': AircallCallbackUrl,
            'client_id': AircallClientId,
            'client_secret': AircallClientSecret,
            'grant_type': 'authorization_code'
        };

        return axios({
            method: 'post',
            url: 'https://api.aircall.io/v1/oauth/token',
            headers: { 'Content-Type': 'application/json' },
            data: body
        }).then((results) => {
            dispatch({type: AIRCALL_SET_ACCESS_TOKEN, token: results.data.access_token})
            return results.data.access_token;
        }).catch(err => {
            throw err;
        });
    }
}

export const generateAircallNumber = (phone) => {
    return (dispatch, getState, {rcsdk}) => {
        return axios({
            method: 'post',
            url: `${PHONE_MANAGMENT_URL}/aircall/buy`,
            data: { phone: phone }
        }).then((results) => {
            return results.data.phone;
        }).catch(err => {
            throw err;
        });
    }
}