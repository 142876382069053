import React from "react"
import { FormattedMessage } from "react-intl";
import FaqService from "../../../services/FAQService.js"
// import {omit} from "lodash";

export const FETCH_FAQS_PENDING = 'FETCH_FAQS_PENDING';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_ERROR = 'FETCH_FAQS_ERROR';
export const ADD_FAQ = 'ADD_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';

export const FETCH_FAQ_CATEGORIES = "FETCH_FAQ_CATEGORIES";
export const ADD_FAQ_CATEGORY = "ADD_FAQ_CATEGORY";
export const UPDATE_FAQ_CATEGORY = "UPDATE_FAQ_CATEGORY";
export const DELETE_FAQ_CATEGORY = "DELETE_FAQ_CATEGORY";
// export const SET_CURRENT_FAQ_CATEGORY = "SET_CURRENT_FAQ_CATEGORY";
export const TRAIN_FAQS = "TRAIN_FAQS";

let faqService = new FaqService();

function fetchFaqsPending() {
    return {
        type: FETCH_FAQS_PENDING
    }
}

function fetchFaqsSuccess(faqs) {
    return {
        type: FETCH_FAQS_SUCCESS,
        faqs: faqs
    }
}

function fetchFaqsError(error) {
    return {
        type: FETCH_FAQS_ERROR,
        error: error
    }
}

export const fetchFaqs = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchFaqsPending());
        return rcsdk.getFaqs().then(faqs => {
            return dispatch(fetchFaqsSuccess(faqs));
            // let faqsScorePromises = [];
            // faqs.map(faq => {
            //     faqsScorePromises.push(
            //         faqService.getScore({question: faq.question, answer: faq.answer}).catch(err => err)
            //     );
            //     return null;
            // });

            // Promise.all(faqsScorePromises).then(response => {
            //     let formatedFaqs = faqs.map((faq, faqIdx) => {
            //         return {...faq, feedback: omit(response[faqIdx].feedback, ["question", "answer"])}
            //     });
            //     return dispatch(fetchFaqsSuccess(formatedFaqs));
            // }).catch(err => {
            //     dispatch(fetchFaqsError(err.message));
            // })

        }).catch(err => {
            dispatch(fetchFaqsError(err.message));
        })
    }
}

function addFaqSuccess(faq){
    return {
        type: ADD_FAQ,
        faq: faq,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="faqs.toast.add" defaultMessage="FAQ added successfully"/>
        }
    }
}

export const addFaq = (datas) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchFaqsPending());

        datas = {...datas, companyId: getState().company.company.id};

        return rcsdk.createFaq(datas)
        .then((faq) => {
            dispatch(addFaqSuccess(faq));
            return faq;
        }).catch(err => {
            dispatch(fetchFaqsError(err.message));
        });
    }
}

function updateFaqSuccess(faq, showToast){
    return {
        type: UPDATE_FAQ,
        faq: faq,
        successToast: showToast ? {
            type: "UPDATE",
            message: <FormattedMessage id="faqs.toast.update" defaultMessage="FAQ updated successfully"/>
        } : null
    }
}

export const updateFaq = ({id, datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchFaqsPending());

        return rcsdk.updateFaq(id, datas)
        .then((response) => {
            dispatch(updateFaqSuccess(response, showToast));
        }).catch(err => {
            dispatch(fetchFaqsError(err.message));
        });
    }
}

function deleteFaqSuccess(faqId, showToast){
    return {
        type: DELETE_FAQ,
        id: faqId,
        successToast: showToast ? {
            type: "DELETE",
            message: <FormattedMessage id="faqs.toast.delete" defaultMessage="FAQ deleted successfully"/>
        } : null
    }
}

export const deleteFaq = (id, showToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchFaqsPending());

        return rcsdk.deleteFaq(id)
        .then((response) => {
            dispatch(deleteFaqSuccess(id, showToast));
        }).catch(err => {
            dispatch(fetchFaqsError(err.message));
        });
    }
}


// export const getFeedback = (faq) => {
//     return (dispatch, getState, {rcsdk}) => {
//         return faqService.getScore({question: faq.question, answer: faq.answer})
//         .then(resp => {
//             return resp.feedback;
//         })
//         .catch(err => err)
//     }
// }

export const getAudio = (text) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk
            ._request()
            .route(`/tools/voice`)
            .params({text: text})
            .err(`Could not get audio`)
            .then((results) => {
                return results;
            });
    }
}



export const fetchFaqsCategories = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: FETCH_FAQ_CATEGORIES, status: "pending"});
        return rcsdk.getFaqCategories().then(resp => {
            dispatch({type: FETCH_FAQ_CATEGORIES, status: "success", faqsCategories: resp});
        }).catch(err => {
            dispatch({type: FETCH_FAQ_CATEGORIES, status: "error", error: err.message});
        })
    }
}


export const addFaqCategory = (data) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: ADD_FAQ_CATEGORY, status: "pending"});
        return rcsdk.createFaqCategory({companyId: getState().company.company.id, ...data}).then(resp => {
            dispatch({type: ADD_FAQ_CATEGORY, status: "success", faqCategory: resp});
        }).catch(err => {
            dispatch({type: ADD_FAQ_CATEGORY, status: "error", error: err.message});
        })
    }
}

export const updateFaqCategory = (id, data) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: UPDATE_FAQ_CATEGORY, status: "pending"});
        return rcsdk.updateFaqCategory(id, data).then(resp => {
            dispatch({type: UPDATE_FAQ_CATEGORY, status: "success", faqCategory: resp});
        }).catch(err => {
            dispatch({type: UPDATE_FAQ_CATEGORY, status: "error", error: err.message});
        })
    }
}

export const deleteFaqCategory = (id, action) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: DELETE_FAQ_CATEGORY, status: "pending"});

        let deleteCategory = rcsdk.deleteFaqCategory(id).then(resp => {
            dispatch({type: DELETE_FAQ_CATEGORY, status: "success", id: id});
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch({type: DELETE_FAQ_CATEGORY, status: "error", error: err.message});
            } else {
                dispatch({type: DELETE_FAQ_CATEGORY, status: "success", id: id});
            }
        })

        if(!action){
            return deleteCategory;
        } else {
            let relatedFaqs = getState().faqs.faqs.filter(el => el.categoryId === id);
            let relatedFaqsPromises = relatedFaqs.map(relatedFaq => {
                if(action === "delete"){
                    return dispatch(deleteFaq(relatedFaq.id, false));
                } else if(action === "move"){
                    return dispatch(updateFaq({id: relatedFaq.id , datas: {categoryId: null}}));
                }
                return null;
            }).filter(el => el)

            return Promise.all([...relatedFaqsPromises]).then(() => {
                return deleteCategory;
            });
        }
    }
}

export const trainFAQs = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: TRAIN_FAQS, status: "pending"})
        return faqService.update().then(resp => {
            dispatch({
                type: TRAIN_FAQS,
                status: "success",
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="faqs.toast.train" defaultMessage="FAQs trained successfully"/>
                }
            })
        }).catch(err => {
            dispatch({type: TRAIN_FAQS, status: "error"})
        })
    }
}  