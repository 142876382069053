import React from 'react';
import { toast } from "react-toastify";
import { toLoopbackFilter } from '@reecall/external_utils'
import { toMongoFilter } from '@reecall/internal_utils'
import moment from "moment";
import UnknowAvatar from "../assets/img/avatar-unknown.png"
import { FormattedMessage } from "react-intl";
import { dateTimeRegexp, dateRegexp, RGBRegexp } from "./regexs"
import { isString, isEqual, transform, isObject } from 'lodash';



var _ = require('lodash');

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export function getCountryCode(countryShortCode) {
    return phoneUtil.getCountryCodeForRegion(countryShortCode.toUpperCase());
}

export function getInitiales(element) {
    let firstName = (element?.originalFirstName || element?.firstName || '').replace(/^\s*(.).*/, '$1').toUpperCase();
    let lastName = (element?.originalLastName || element?.lastName || '').replace(/^\s*(.).*/, '$1').toUpperCase();
    return firstName || lastName ? `${firstName || ''}${lastName || ''}` : '?';
}

export function getDefaultAvatar({ firstName, lastName }, size = 128) {
    firstName = (firstName || '').replace(/^\s*(.).*/, '$1');
    lastName = (lastName || '').replace(/^\s*(.).*/, '$1');

    if (firstName === "" && lastName === "") return UnknowAvatar;

    return `https://ui-avatars.com/api/?name=${firstName || lastName ? `${firstName || ''}${lastName || ''}` : '?'}&size=${size}`;
}

export function getDefaultNames({ firstName, lastName }, reverse = false, defaultValue = 'inconnu') {
    const n = [];
    if (!firstName && !lastName) return [defaultValue];
    n.push((firstName || defaultValue).replace(/([^-_ \t])([^-_ \t]*)/ig, (m, a, b) => `${a.toUpperCase()}${b.toLowerCase()}`));
    n.push((lastName || defaultValue).toUpperCase());
    if (reverse) n.reverse();
    return n.filter(a => a);
}

export function truncateWithEllipses(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
}

export function searchByText(collection, text, exclude) {
    text = _.toLower(text);
    return _.filter(collection, function (object) {
        return _(object).omit(exclude).some(function (string) {
            return _(string).toLower().includes(text);
        });
    });
}

export function getTicketColorByTreatmentDelay(minutes) {
    if (minutes < 60) {
        return "#ff8614"
    } else {
        return "#ff524a"
    }
    // return getColorBetween([255, 84, 81], [255, 152, 0], minutes / 1440 );
}


export const hex2RGB = str => {
    const [, short, long] = String(str).match(RGBRegexp) || [];

    if (long) {
        const value = Number.parseInt(long, 16);
        // eslint-disable-next-line
        return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
    } else if (short) {
        return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
    }
};

export function getColorBetween(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    if (factor > 1) factor = 1;
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
}

export function getRandom(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomArray(aLength, min, max) {
    return Array.from({ length: aLength }, () => getRandom(min, max));
}

export function getRandomIntArray(aLength, min, max) {
    return Array.from({ length: aLength }, () => getRandomInt(min, max));
}


const contextRegexp = new RegExp("{{(.+?)}}");
const isContextValue = (value, regExp = null) => {
    if (regExp) {
        return regExp.test(value);
    }
    return contextRegexp.test(value);
}
const contextRegexpGlobal = RegExp("{{(.+?)}}",'g');
const getStringContextKeys = (str = "") => {
    if(typeof str !== "string"){ return [] }
    return [...str.matchAll(contextRegexpGlobal)].map(el => el[1]);
}
export { isContextValue, getStringContextKeys };


const formatQaFieldsDefaultValue = (fields, datas) => {
    return [...fields.map(el => {
        let defaultValue = datas?.[el.key];
        return {
            ...el,
            default: typeof defaultValue !== "undefined" ? defaultValue : null
        };
    })]
}

const formatQaDatas = (fields, values) => {
    return [...fields.map(el => {
        if (el.type === "dictionary") {
            return {
                [el.key]: Object.fromEntries(values[el.key].map(row => {
                    return [row.key, row.value]
                }))
            }
        }
        return { [el.key]: values[el.key] }
    })].reduce((obj, item) => {
        return Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] });
    }, {})
}

export { formatQaFieldsDefaultValue, formatQaDatas }

export function HasUndefinedContextValues(entity, context) {
    let hasUndefinedContextValues = false;
    var regExp = new RegExp("{{(.+?)}}", 'g');
    let stringifyEntity = JSON.stringify(entity);
    const matches = [...stringifyEntity.matchAll(regExp)];
    matches.forEach(match => {
        if (!!!_.get(context, match[1])) {
            hasUndefinedContextValues = true;
            return;
        }
    })

    return hasUndefinedContextValues;
};


/**
  * Replaces all occurrences of needle (interpreted as a regular expression with replacement and returns the new object.
  * 
  * @param entity The object on which the context replacements should be applied to
  * @param needle The search phrase (as a regular expression)
  * @param context Context value for replacement
  * @param affectsKeys[optional=true] Whether keys should be replaced
  * @param affectsValues[optional=true] Whether values should be replaced
  */
export function ReplaceByContext(entity, needle, context, affectsKeys, affectsValues, replaceEmpty = false) {
    affectsKeys = typeof affectsKeys === "undefined" ? false : affectsKeys;
    affectsValues = typeof affectsValues === "undefined" ? true : affectsValues;

    var newEntity = {},
        regExp = new RegExp(needle, 'g');

    if (entity === null) {
        return null
    } else if (Array.isArray(entity)) {
        newEntity = [];
        entity.forEach(value => {
            if (typeof value === "object") {
                value = ReplaceByContext(value, needle, context, affectsKeys, affectsValues, replaceEmpty);
            } else if (typeof value === "string") {
                value = value.replace(regExp, function (a, b) {
                    return _.get(context, b) || replaceEmpty || `{{${b}}}`
                });
            }
            newEntity.push(value);
        });
    } else {
        for (var property in entity) {
            if (!entity.hasOwnProperty(property)) {
                continue;
            }

            var value = entity[property],
                newProperty = property;

            if (affectsKeys) {
                newProperty = property.replace(regExp, function (a, b) {
                    return _.get(context, b) || replaceEmpty || `{{${b}}}`
                });
            }

            if (affectsValues) {
                if (typeof value === "object") {
                    value = ReplaceByContext(value, needle, context, affectsKeys, affectsValues, replaceEmpty);
                } else if (typeof value === "string") {
                    value = value.replace(regExp, function (a, b) {
                        return _.get(context, b) || replaceEmpty || `{{${b}}}`
                    });
                }
            }

            newEntity[newProperty] = value;
        }
    }
    return newEntity;
};

/**
  * Replaces all occurrences of needle (interpreted as a regular expression with replacement and returns the new object.
  * 
  * @param entity The object on which the replacements should be applied to
  * @param needle The search phrase (as a regular expression)
  * @param replacement Replacement value
  * @param affectsKeys[optional=true] Whether keys should be replaced
  * @param affectsValues[optional=true] Whether values should be replaced
  */
export function replaceAll(entity, needle, replacement, affectsKeys = false, affectsValues) {
    affectsKeys = typeof affectsKeys === "undefined" ? true : affectsKeys;
    affectsValues = typeof affectsValues === "undefined" ? true : affectsValues;

    var newEntity = {},
        regExp = new RegExp(needle, 'g');
    for (var property in entity) {
        if (!entity.hasOwnProperty(property)) {
            continue;
        }

        var value = entity[property],
            newProperty = property;

        if (affectsKeys) {
            newProperty = property.replace(regExp, replacement);
        }

        if (affectsValues) {
            if (typeof value === "object") {
                value = replaceAll(value, needle, replacement, affectsKeys, affectsValues);
            } else if (typeof value === "string") {
                value = value.replace(regExp, replacement);
            }
        }

        newEntity[newProperty] = value;
    }

    return newEntity;
};

export const processCSVData = (csv, separator) => {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(separator);
        var tarr = [];
        for (var j = 0; j < data.length; j++) {
            tarr.push(data[j]);
        }
        lines.push(tarr);
    }
    return lines;
}

export const string_to_slug = (str, removeWhitespace = true) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    if (removeWhitespace) {
        str = str.replace(/\s+/g, '-') // collapse whitespace and replace by -
    }
    str = str.replace(/-+/g, '-'); // collapse dashes

    return str;
}


export function formatPhoneNumberForContact(phoneNumber) {
    let number = phoneUtil.parse(phoneNumber, "FR");
    return phoneUtil.format(number, PNF.E164).replaceAll(" ", "");
}


export function validatePhoneNumber(number) {
    let formatedPhoneNumber = null;
    let isValidPhoneNumber = null;
    let phoneNumber = null;

    try {
        phoneNumber = phoneUtil.parseAndKeepRawInput(number, 'FR');
    } catch (err) {
        isValidPhoneNumber = false;
    };

    if (phoneNumber && phoneUtil.isValidNumber(phoneNumber)) {
        var prefix = phoneNumber.getCountryCode();
        var num = phoneNumber.getNationalNumber();
        formatedPhoneNumber = '+' + prefix + num + '';
        isValidPhoneNumber = true;
    } else {
        isValidPhoneNumber = false;
    }

    return { isValidPhoneNumber: isValidPhoneNumber, formatedPhoneNumber: formatedPhoneNumber };
}

export const copyToClipboard = (str, type, showToast = true) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (showToast) {
        toast.success(`${type} copied into clipboard`);
    }
};


// get Data from all totalCards to export csv on dashboard page
export const getDataForCSV = (data, dataToExport, cb) => {
    const array = [...dataToExport]
    const index = dataToExport.findIndex(e => e.type === data.type)
    if (index !== -1) {
        array[index] = data
        cb(array)
    } else cb([...dataToExport, data])
}


export function flatten(object, separator = '.') {
    return Object.assign({}, ...function _flatten(child, path = []) {
        return [].concat(...Object.keys(child).map(key => typeof child[key] === 'object'
            ? _flatten(child[key], path.concat([key]))
            : ({ [path.concat([key]).join(separator)]: child[key] })
        ));
    }(object));
}

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":";
    }

    ret += (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}




export const getDecimalDuration = (milliseconds, forceFormat = null) => {
    if (milliseconds === null) return null;
    let formatedDuration;
    if (!forceFormat) {
        let tmpLocale = localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';
        var roundingDefault = moment.relativeTimeRounding();
        moment.relativeTimeRounding((value) => {
            return Math.round((value + Number.EPSILON) * 100) / 100;
        });

        formatedDuration = moment().locale(`small_${tmpLocale}`);
        formatedDuration = formatedDuration.add({ milliseconds: milliseconds }).fromNow(true)

        // back to default
        moment.relativeTimeRounding(roundingDefault);
        return formatedDuration;
    } else {
        formatedDuration = moment.duration(milliseconds);
        return Math.round(formatedDuration?.[forceFormat]());
    }
}


export function getHours(duration) //millisecond
{
    // Hours
    var hrs = Number.parseFloat(duration / 3600000).toPrecision(1);
    return hrs;
}

export function getFormattedDuration(duration) //millisecond
{
    let time;
    let result;
    if (duration / 3600000 > 24) {
        time = parseFloat(duration / 3600000 / 24).toFixed(1)
        result = <FormattedMessage id="formattedDuration.days" defaultMessage="{time} d" values={{ time: time }} />
    } else if (duration / 3600000 < 1) {
        time = parseFloat(duration / 60000).toFixed(0)
        result = <FormattedMessage
            id="formattedDuration.mins"
            defaultMessage="{time} min"
            values={{ time: time }}
        />
    } else {
        time = parseFloat(duration / 3600000).toFixed(1)
        result = <FormattedMessage id="formattedDuration.hours" defaultMessage="{time} h" values={{ time: time }} />
    }
    return result;
}


export const getVariation = (currVal = null, prevVal = null, forceValue = false) => {

    if ((prevVal === 0 || currVal === 0 || prevVal === null || currVal === null) && forceValue) return "—";

    if (prevVal === null || prevVal === 0) return null;
    if (currVal === null || currVal === 0) return null;

    return Math.round(((currVal - prevVal) / prevVal) * 100);
}



/**
 * largestRemainderRound will round each number in an array to the nearest
 * integer but make sure that the the sum of all the numbers still equals
 * desiredTotal. Uses largest remainder method.  Returns numbers in order they
 * came.
 *
 * @param {number[]} numbers - numbers to round
 * @param {number} desiredTotal - total that sum of the return list must equal
 * @return {number[]} the list of rounded numbers
 * @example
 *
 * var numbers = [13.6263, 47.9896, 9.5960, 28.7880];
 * largestRemainderRound(numbers, 100);
 *
 * // => [14, 48, 9, 29]
 *
 */
export const largestRemainderRound = (numbers, desiredTotal) => {
    var result = numbers.map(function (number, index) {
        return {
            floor: Math.floor(number),
            remainder: getRemainder(number),
            index: index,
        };
    }).sort(function (a, b) {
        return b.remainder - a.remainder;
    });

    var lowerSum = result.reduce(function (sum, current) {
        return sum + current.floor;
    }, 0);

    var delta = desiredTotal - lowerSum;
    for (var i = 0; i < delta; i++) {
        result[i].floor++;
    }

    return result.sort(function (a, b) {
        return a.index - b.index;
    }).map(function (result) {
        return result.floor;
    });
}

function getRemainder(number) {
    var remainder = number - Math.floor(number);
    return remainder.toFixed(4);
}

//Flatten object to {path.1: value, path.2: value2, ...}
export const flattenKeys = (obj, path = []) =>
    !_.isObject(obj)
        ? { [path.join('.')]: obj }
        : _.reduce(obj, (cum, next, key) => _.merge(cum, flattenKeys(next, [...path, key])), {});


export const unflattenKeys = _.flow([
    _.toPairs,
    (arr) => _.reduce(arr, (cum, [key, value]) => _.set(cum, key, value), {}),
]);

export const isDate = (str) => {
    return dateRegexp.test(str);
}

export const isDateTime = (str) => {
    return dateTimeRegexp.test(str);
}

export const getModelURI = ({type = "conversation", id, parentId = null, withoutBasename = false}) => {
    if(!id) return null;
    const baseName  = window.location.origin;
    let modelURI    = ({
        "conversation"  : `/conversations/all/${id}`,
        "ticket"        : `/tickets/all/${id}`,
        "contact"       : `/contacts/${id}`,
        "channel"       : `/settings/channels/details/${id}`,
        "agent"         : `/settings/agents/details/${id}`,
        "faq"           : `/settings/faqs/details/${id}`,
        "collection"    : `/settings/collections/details/${id}`,
        "team"          : `/settings/teams/details/${id}`,
        "members"       : `/settings/members/details/${id}`,
        "export"        : `/settings/exports/details/${id}`,
        "tagCategory"   : `/settings/tags/${id}`,
        "tag"           : `/settings/tags/${parentId}/${id}`,
        "rule"          : `/settings/rules/details/${id}`,
    })?.[type]

    return modelURI ? `${withoutBasename ? "" : baseName}${modelURI}` : null;
}


export const getIconFromString = (str) => {
    let iconProps = str.includes(":") ?
        str.split(/(%7C|\|)/gm).reduce(
            (previousValue, currentValue) => {
                let [key, val] = currentValue.split(":");
                return {
                    ...previousValue,
                    [key]: val
                }
            },
            {}
        )
        :
        { icon: str }

    return iconProps
}

export const getBoolean = (val) => {
    if (isString(val)) {
        switch (val.toLowerCase().trim()) {
            case "true":
            case "yes":
            case "1":
                return true;
            case "false":
            case "no":
            case "0":
            case null:
            default:
                return false;
        }
    }
    return Boolean(val)
}

export const iterateWhereToMongo = (obj) => {
    Object.keys(obj).forEach(key => {
        let formattedKey = key;
        if (["eq", "neq", "gt", "gte", "lt", "lte", "in", "nin", "and", "or", "inq"].includes(key)) {
            switch (key) {
                default:
                    formattedKey = `$${key}`
                    break;
                case "inq":
                    formattedKey = `$in`
                    break;
                case "neq":
                    formattedKey = `$ne`
                    break;
            }
            delete Object.assign(obj, { [formattedKey]: obj[key] })[key];
        }

        if (typeof obj[formattedKey] === 'object' && obj[formattedKey] !== null) {
            iterateWhereToMongo(obj[formattedKey])
        } else if (Array.isArray(obj[formattedKey])) {
            obj[formattedKey].forEach(row => {
                iterateWhereToMongo(row)
            })
        }
    })
}

export const getRequestWhere = ({ where = null, companyId, filters, toMongo = false }) => {
    let whereMongo;
    if (where && toMongo) {
        whereMongo = Object.assign({}, where);
        iterateWhereToMongo(whereMongo)
    }

    let loopbackWhere = [
        Object.assign(
            { companyId: { [toMongo ? "$eq" : "eq"]: companyId } },
            toMongo ? whereMongo : null,
            !toMongo ? where : null,
        )
    ]

    if (filters && filters.length > 0) {
        filters = filters.map(filter => {
            if (["channelId", "contactId", "assigneeId", "agentId"].includes(filter.source)) {
                return {
                    ...filter,
                    type: "text"
                }
            }
            
            if(filter?.isIds){
                if(["IN", "NOT IN"].includes(filter.filter)){
                    return {
                        ...filter,
                        value: Array.isArray(filter.value) 
                            ? filter.value.map(val => `tid:${val}`)
                            : `tid:${filter.value}`,
                        type: "array"
                    }
                } else if(filter.filter === "EMPTY") {
                    return {
                        ...filter,
                        filter: "EQUAL",
                        value: [],
                        type: "array"
                    }
                } else if(filter.filter === "NOT EMPTY") {
                    return {
                        ...filter,
                        filter: "NOT EQUAL",
                        value: [],
                        type: "array"
                    }
                }
                return filter
            }
            return filter
        })

        if (toMongo) {
            filters.map(filter => {
                if(filter?.isCollectedDatas){
                    return loopbackWhere.push({
                        collectedData: {
                            $elemMatch: {
                                label: filter.source,
                                ...toMongoFilter(({...filter, source: "value"}))
                            }
                        }
                    })
                } else {
                    return loopbackWhere.push(toMongoFilter((filter)))
                }
            })
        } else {
            toLoopbackFilter(filters).and.map(filter => loopbackWhere.push(filter));
        }
    }

    loopbackWhere = loopbackWhere
        .filter(el => el)
        .filter(el => (Object.keys(el).length !== 0));

    return loopbackWhere;
} 

export function formatYearlessL(date) {
    let formatL = moment.localeData().longDateFormat('L');
    let formatYearlessL = formatL.replace(/YYYY/g,'YY');
    return moment(date).format(formatYearlessL);
}

export const difference = (object, base) => {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base)
}
