import React from "react"
import {FormattedMessage} from "react-intl";

import {omit} from "lodash";
import { v4 as uuid } from 'uuid';
import { push } from 'connected-react-router'

import { setSoftPhoneSource, setUserPhoneStatus } from "../call";

export const FETCH_CHANNELS_PENDING = 'FETCH_CHANNELS_PENDING';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_ERROR = 'FETCH_CHANNELS_ERROR';

export const FLUSH_CHANNELS = 'FLUSH_CHANNELS';

export const ADD_CHANNEL = 'ADD_CHANNEL';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';

export const FETCH_CHANNEL_CONNECTIONS_SUCCESS = 'FETCH_CHANNEL_CONNECTIONS_SUCCESS';
export const FETCH_CHANNEL_CONNECTIONS_ERROR = 'FETCH_CHANNEL_CONNECTIONS_ERROR';

export const UPDATE_CHANNEL_CONNECTION = 'UPDATE_CHANNEL_CONNECTION';
export const ADD_CHANNEL_CONNECTION = 'ADD_CHANNEL_CONNECTION';
export const DELETE_CHANNEL_CONNECTION = 'DELETE_CHANNEL_CONNECTION';


function fetchChannelsPending() {
    return {
        type: FETCH_CHANNELS_PENDING
    }
}

function fetchChannelsSuccess(channels) {
    return {
        type: FETCH_CHANNELS_SUCCESS,
        channels: channels
    }
}

function fetchChannelsError(error) {
    return {
        type: FETCH_CHANNELS_ERROR,
        error: error
    }
}

export const fetchChannels = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchChannelsPending());

        return rcsdk.getChannels().include("acd").then(response => {
            dispatch(fetchChannelsSuccess(response));
            if(response.filter(channel => channel.type === "phone" || channel.type === "phonevia")?.[0]){
                dispatch(setSoftPhoneSource(response.filter(channel => channel.type === "phone" || channel.type === "phonevia")?.[0].id));
            }
            
        }).catch(err => {
            dispatch(fetchChannelsError(err.message));
        })
    }
}

export const flushChannels = () => {
    return {
        type: FLUSH_CHANNELS
    }
}

export function addChannel(channel, integrationConnection, redirection = true, showToast = true) {
    return (dispatch, getState, {rcsdk}) => {
        let token = null;
        let data = {
            ...channel.datas,
            live: true,
            escalation: true
        };
        switch(channel.type){
            case "phone":
            case "phonevia":
                token = channel.datas.phone;
                data = omit({...data}, ["phone"]);
                break;
            case "chat":
            case "clicktocall":
                token = uuid();
                break;
            case "aircall":
                token = channel.datas.phone;
                data = omit({...data}, ["phone"]);
                break;
            case "wildix":
            case "threecx":
                token = channel.datas.token;
                data = omit({...data}, ["token"]);
                break;
            default:
                break;
        }

        let formattedChannel = {
            ...omit(channel, ["config", "i18n", "helpLink", "helpText", "icon", "color", "live", "datas", "tokenFieldType", "tokenHelpSelector"]),
            name: channel.i18n.label.defaultMessage,
            data: data,
            token: token,
            agentId: '*',
            companyId: getState().company.company.id
        };

        return rcsdk.createChannel(formattedChannel).then(response => {
            dispatch({ 
                type: ADD_CHANNEL,
                channel: response,
                successToast: showToast ? {
                    type: "ADD",
                    message: <FormattedMessage id="channels.toast.add" defaultMessage="Channel added successfully"/>
                } : null
            });
            if(redirection){
                dispatch(push(`/settings/channels/details/${response.id}`));
            }
        }).catch(err => {
            dispatch(fetchChannelsError(err.message));
        })
    }
}

export function updateChannel(channelId, datas) {
    return (dispatch, getState, {rcsdk}) => {
        let ACDPromise = new Promise(resolve => { resolve() });
        let ACDPromiseType = null;
        if(datas.acd.id){
            if(datas.acd.members.length > 0){
                ACDPromise = rcsdk.updateACD(datas.acd.id, {...omit(datas.acd, ["id"])});
                ACDPromiseType = "update";
            } else {
                ACDPromise = rcsdk.deleteACD(datas.acd.id);
                ACDPromiseType = "delete";
            }
        } else {
            if(datas.acd.members.length > 0){
                ACDPromise = rcsdk.createACD({
                    ...omit(datas.acd, ["id"]),
                    "mode": "queue",
                    "config": {},
                    "name": `ACD ${getState().company.company.name} - ${getState().channels.channels.find(el => el.id === channelId).name}`,
                    "companyId": getState().company.company.id
                })
                ACDPromiseType = "create";
            }
        }

        const updateChannelPromise = ({channelDatas, acd}) => {
            return rcsdk.updateChannel(channelId, {...channelDatas, gdprTracker: channelDatas?.gdprTracker !== "" ? channelDatas?.gdprTracker : null}).then(response => {
                dispatch({ 
                    type: UPDATE_CHANNEL,
                    channel: response,
                    acd: {...acd},
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="channels.toast.update" defaultMessage="Channel updated successfully"/>
                    }
                });
            }).catch(err => {
                dispatch(fetchChannelsError(err.message));
            }) 
        }

        let channelDatas = omit(datas, ["acd"]);

        return ACDPromise.then(respACD => {
            if(respACD?.id){
                channelDatas = {...channelDatas, acdId: respACD?.id};
            }
            return updateChannelPromise({channelDatas: channelDatas, acd: respACD});
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchChannelsError(err.message));
            } else {
                if(ACDPromiseType === "delete"){
                    channelDatas = {...channelDatas, acdId: null};
                }
                return updateChannelPromise({channelDatas: channelDatas, acd: null});
            }
        })
    }
}

export function deleteChannel(channelId){
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.deleteChannel(channelId).then(response => {
            dispatch({ 
                type: DELETE_CHANNEL,
                channelId: channelId,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="channels.toast.delete" defaultMessage="Channel deleted successfully"/>
                }
            });
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchChannelsError(err.message));
            } else {
                dispatch({ type: DELETE_CHANNEL, channelId: channelId });
            }
        })
    }
}

export const fetchChannelConnections = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchChannelsPending());

        return rcsdk.getChannelConnections().then(response => {
            dispatch({
                type: FETCH_CHANNEL_CONNECTIONS_SUCCESS,
                channelConnections: response
            });
        }).catch(err => {
            dispatch({
                type: FETCH_CHANNEL_CONNECTIONS_ERROR,
                error: err.message
            });
        })
    }
}

export function addChannelConnection(datas) {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.createChannelConnection(datas).then(response => {
            dispatch({
                type: ADD_CHANNEL_CONNECTION,
                channelConnection: response,
                successToast: {
                    type: "ADD",
                    message: <FormattedMessage id="channelConnections.toast.add" defaultMessage="Channel connections added successfully"/>
                }
            });
        }).catch(err => {
            dispatch(fetchChannelsError(err.message));
        })
    }
}

export function updateChannelConnection(channelConnectionId, datas, showToast = false) {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateChannelConnection(channelConnectionId, datas).then(response => {
            let channel = selectChannelById(getState(), {channelId: response.channelId});
            if(channel.type === "phone"){
                dispatch(setUserPhoneStatus(true));
            }
            let actionDatas = {
                type: UPDATE_CHANNEL_CONNECTION,
                channelConnection: response
            }
            if(showToast) {
                actionDatas = { ...actionDatas,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="channelConnections.toast.update" defaultMessage="Channel connections updated successfully"/>
                    }
                }
            }
            return dispatch({ ...actionDatas });
        }).catch(err => {
            dispatch(fetchChannelsError(err.message));
        })
    }
}

export function deleteChannelConnection(channelConnectionId){
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.deleteChannelConnection(channelConnectionId).then(response => {
            dispatch({
                type: DELETE_CHANNEL_CONNECTION,
                channelConnectionId: channelConnectionId,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="channelConnections.toast.delete" defaultMessage="Channel connections deleted successfully"/>
                }
            });
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchChannelsError(err.message));
            } else {
                dispatch({
                    type: DELETE_CHANNEL_CONNECTION,
                    channelConnectionId: channelConnectionId,
                    successToast: {
                        type: "DELETE",
                        message: <FormattedMessage id="channelConnections.toast.delete" defaultMessage="Channel connections deleted successfully"/>
                    }
                });
            }
        })
    }
}

export function updateChannelConnections(channelConnectionIds, datas, showToast = false) {
    return (dispatch, getState, {rcsdk}) => {

        let channelConnnectionsPromises = [];
        channelConnectionIds.map(channelConnectionId => {
            let channelConnnectionsPromise = dispatch(updateChannelConnection(channelConnectionId, datas, showToast));
            channelConnnectionsPromises.push(channelConnnectionsPromise);
            return null;
        });
        return new Promise(resolve => {
            Promise.all([...channelConnnectionsPromises]).then(() => {
                resolve("success");
            })
        });

        // return channelConnectionIds.map(channelConnectionId => {
        //     return dispatch(updateChannelConnection(channelConnectionId, datas, showToast));
        // })
    }
}

const selectChannelsConnections = state => state.channels.channelConnections;
const selectChannels = state => state.channels.channels;
const selectLive = state => state.reduce(element => element?.data?.live);
const selectLiveChannels = state => selectLive(selectChannels(state));

const selectChannelsConnectionsByMemberId = (state, {memberId}) => selectChannelsConnections(state).filter(el => el.memberId === memberId);
const selectMemberChannelsConnections = state => selectChannelsConnections(state).filter(el => el.memberId === state.user.rcuser.rcId);
const selectChannelsConnectionsByChannelId = (state, {channelId}) => selectChannelsConnections(state).filter(el => el.channelId === channelId);
const selectChannelById = (state, {channelId}) => selectChannels(state).filter(el => el.id === channelId)[0];

const selectMemberPhoneChannelConnections = state => {
    let memberChannelConnections = selectMemberChannelsConnections(state);
    return memberChannelConnections.filter(channelConnection => {
        let channel = selectChannelById(state, {channelId: channelConnection.channelId});
        return channel.type === "phone"
    });
}

const selectMemberChatActiveConnections = state => {
    let memberChannelConnections = selectMemberChannelsConnections(state);
    return memberChannelConnections.filter(channelConnection => {
        let channel = selectChannelById(state, {channelId: channelConnection.channelId});
        return channel.type === "chat" && channelConnection.active
    });
}

const selectPhoneChannels = state => selectChannels(state).filter(channel => channel.type === "phone");
const selectChatChannels = state => selectChannels(state).filter(channel => channel.type === "chat");
const selectAircallChannels = state => selectChannels(state).filter(channel => channel.type === "aircall");

const selectReecallChatChannel = state => selectChatChannels(state).filter(channel => channel.agentId === "*")?.[0];

export { 
    selectChannelsConnections,
    selectChannels,
    selectChannelById,
    selectLive,
    selectLiveChannels,
    selectChannelsConnectionsByMemberId,
    selectMemberChannelsConnections,
    selectChannelsConnectionsByChannelId,
    selectMemberPhoneChannelConnections,
    selectMemberChatActiveConnections,
    selectPhoneChannels,
    selectChatChannels,
    selectAircallChannels,
    selectReecallChatChannel
}