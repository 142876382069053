import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translations } from "./config";
import { injectIntl } from 'react-intl'

const ExportToastMessage = injectIntl(({ intl, action, status }) => {
    const icons = {
        creation: "plus",
        update: "pen",
        deletion: "trash",
    }
    return <div className='d-flex align-items-center'>
        <FontAwesomeIcon icon={["fas", icons[action]]} className="mr-50" />
        {intl.formatMessage(translations[action + status.capitalize()])}
    </div>
})

export default ExportToastMessage;