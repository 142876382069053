import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import { delayConfiguration } from 'pusher-redux';
import rootReducer from "../reducers/rootReducer"
import ReecallSdk from "@reecall/reecall_sdk";
import { history } from "../../history"
import RPAService from "../../services/RPAService"
import PaymentService from "../../services/PaymentService"
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from "../../configs/pusherConfig";
import { CORE_URL, API_BASE, SEARCH_URL } from "../../configs/constants"

import {throttle} from 'lodash';

const DEBUG = window.location.hostname === "localhost" ? true : false;

const rcsdk = new ReecallSdk(localStorage.getItem('id_token') || 'notoken', {
    withStats: true
});

if (API_BASE) {
    rcsdk.API_BASE = `${API_BASE}/api`;
    // rcsdk.API_BASE = 'http://localhost:3090/api';
}

if (SEARCH_URL) {
    rcsdk.Search.API_BASE = `${SEARCH_URL}/api`;
}

const rcRPA = new RPAService();
const rcPayment = new PaymentService();

const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error
})

const middlewares = [thunk.withExtraArgument({rcsdk, rcRPA, rcPayment}), createDebounce(), routerMiddleware(history), DEBUG && loggerMiddleware].filter(Boolean);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer(history),
    {},
    composeEnhancers(applyMiddleware(...middlewares))
)

store.subscribe(throttle(() => {
    if (store.getState().user?.rcuser?.rcId) {
        //Lors d'une subscribtion au store, on stock les "lives" actuels dans le localStorage
        localStorage.setItem(`rcLivesState-${store.getState().user?.rcuser?.rcId}`, JSON.stringify(store.getState().lives.lives))
    }
}, 1000))

delayConfiguration(store, PUSHER_APP_KEY, {
    cluster: PUSHER_CLUSTER,
    authEndpoint: CORE_URL + "chat/getToken"
});

export { store }
