import axios from "axios";
import { RPA_URL } from "../configs/constants"

export class RequestBuilder {
    constructor(options = {}, BASE) {
        this.options = { method: 'GET', params: { filter: {} }, data: {}, headers: {}, ...options};
        this.BASE = BASE || `${RPA_URL}`;
        this.RAW = false;
    }

    url(url) { this.options.url = url; return this; }
    route(route) { return this.url(`${this.BASE}${route}`); }
    slash(route) { return this.url(`${this.options.url}/${route}`); }
    method(method) { this.options.method = method; return this; }
    err(err) { this.err = err; return this; }
    data(data) { this.options.data = data; return this; }

    // Additional options
    raw(r = true) { this.RAW = r; return this; }

    // Build the axios request, after calling any of the methods below, config methods won't have any effect.
    build() {
        const r = axios(this.options).then(res => this.RAW === true ? res : res.data);
        if (this.err) {
            return r.catch(error => {
                const err = new Error(this.err);
                err.error = error;
                throw err;
            });
        }
        return r;
    }

    // Promise-like stuff
    then(a, b) { return this.build().then(a, b); }
    catch(a) { return this.build().catch(a); }

    // Helper stuff
    print() { this.then(console.log, console.error) }
}


class RPAService {

    constructor(companyId, user) {
        this.companyId = companyId;
        this.user = user;
    }

    _request() {
        const headers = {
            "x-reecall-source": "APP",
            "x-reecall-sourceId": this.user.rcId,
            "x-reecall-company": this.companyId
        }
        return new RequestBuilder({ headers });
    }

    setCompanyId = (companyId) => {
        this.companyId = companyId;
    }

    setUser = (user) => {
        this.user = user;
    }


    getContext = (contextIds) => {
        return this._request()
            .method('POST')
            .route(`/context`)
            .data({
                ...contextIds
            })
            .err(`Could not get context`)
    }

    getRulesSources = () => {
        return this._request()
            .method('POST')
            .route(`/rules/sources`)
            .err(`Could not get rules sources`)
    }

    getRulesFilters = () => {
        return this._request()
            .method('POST')
            .route(`/rules/filters`)
            .err(`Could not get rules filters`)
    }

    getCollectedDatas = () => {
        return this._request()
            .route(`/collected/${this.companyId}`)
            .err(`Could not get collected datas`);
    }

    executeQuickAction = ({ id, data }) => {
        return this._request()
            .method('POST')
            .route(`/quickAction/${id}`)
            .data(data)
            .err(`Could not execute Quick action`);
    }

    executeAction = (data) => {
        return this._request()
            .method('POST')
            .route(`/action`)
            .data(data)
            .err(`Could not execute action`);
    }


    hookOnImport = ({ model, results }) => {
        return this._request()
            .method('POST')
            .route(`/trigger/${this.companyId}/${model}.imported`)
            .data({
                member: {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    id: this.user.rcId
                },
                datetime: (new Date()).toISOString(),
                ...results
            })
            .err(`Could not execute hook "onImport"`)
            .then(resp => {
                console.info(`HOOK TRIGGERED: OnImport (${model})`);
                return resp;
            })
    }
}

export default RPAService;