import React from "react"
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { appIntl } from "../../../components/reecall/lang/IntlGlobalProvider";
import { GDPRSentencesDefault } from "../../../configs/channelsConfig"
import { PHONE_MANAGMENT_URL } from "../../../configs/constants"
import { SET_SIGNUP_AGENT } from "../aircall/signup"
import { SET_VIEW_TYPE as SET_VIEW_TYPE_TICKET } from "../tickets/index"
import { SET_VIEW_TYPE as SET_VIEW_TYPE_CONVERSATION } from "../conversations/index"
export const FETCH_COMPANY_PENDING = 'FETCH_COMPANY_PENDING';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';

export const FETCH_COMPANY_SETTINGS = 'FETCH_COMPANY_SETTINGS';
export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';

function fetchCompanyPending() {
    return {
        type: FETCH_COMPANY_PENDING
    }
}

export function fetchCompanySuccess(company) {
    return {
        type: FETCH_COMPANY_SUCCESS,
        company: company
    }
}

function fetchCompanyError(error) {
    return {
        type: FETCH_COMPANY_ERROR,
        error: error
    }
}

export const setCompany = (company) => {
  return (dispatch, getStat, {rcsdk}) => {
    return dispatch(fetchCompanySuccess(company));
  }
}

export const updateCompany = (values) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateCompany(getState().company.company.id, values).then(response => {
            dispatch({
                type: UPDATE_COMPANY,
                company: response
            });
        }).catch(err => {
            dispatch(fetchCompanyError(err.message));
        })
    }
}

export const updateCompanySettings = (value, successToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateCompanySetting(getState().company.companySettings.id, {...getState().company.companySettings.value, ...value}).then(response => {

            // Default view ticket/conversation
            if(response?.value?.viewConfig?.ticket){
                dispatch({type: SET_VIEW_TYPE_TICKET, viewType: response.value.viewConfig.ticket })
            }
            if(response?.value?.viewConfig?.conversation){
                dispatch({type: SET_VIEW_TYPE_CONVERSATION, viewType: response.value.viewConfig.conversation })
            }

            return dispatch({
                type: UPDATE_COMPANY_SETTINGS,
                companySettings: response,
                successToast: successToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="companySettings.toast.update" defaultMessage="Settings updated successfully"/>
                } : null
            });
        }).catch(err => {
            dispatch(fetchCompanyError(err.message));
        })
    }
}

export const fetchCompanySettings = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchCompanyPending());
        return rcsdk.getOrCreateCompanySetting(getState().company.company.id, {"conversationSummaryLayout": [],  "manageOpeningTime": false, "openingTime": []}).then(response => {
            dispatch({
                type: FETCH_COMPANY_SETTINGS,
                companySettings: response
            });

            // Default view ticket/conversation
            if(response?.value?.viewConfig?.ticket){
                dispatch({type: SET_VIEW_TYPE_TICKET, viewType: response.value.viewConfig.ticket })
            }
            if(response?.value?.viewConfig?.conversation){
                dispatch({type: SET_VIEW_TYPE_CONVERSATION, viewType: response.value.viewConfig.conversation })
            }

        }).catch(err => {
            dispatch(fetchCompanyError(err.message));
        })
    }
}


export const generateNumber = async (companyname) => {
    console.log(companyname, "companyname generateNumber");
    return axios({
        method: 'post',
        url: `${PHONE_MANAGMENT_URL}/buy`,
        data: { companyname: companyname }
    }).then((results) => {
        console.log(results, "generateNumber");
        return results.data.phone;
    }).catch(err => {
        console.log(err, "err generateNumber");
        throw err;
    });
}

export const createCompany = (data) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: CREATE_COMPANY, status: "pending" })

        return rcsdk
            .registerCompany(data)
            .then(company => {
                console.log(company, "company");
                
                const agentData = {
                    name: company.name,
                    rank: "junior",
                    companyId: company.id,
                    published: true,
                    config: {
                        speak: "fr-FR",
                        closeConversation: true,
                        noFaq: false,
                        noBasic: false,
                        misunderstandLoop: 1,
                        connections: [],
                        activeSkills: [],
                        skills: {},
                        toCollect: {
                            reason: {
                                question: "Pour quelle raison nous contactez vous ?",
                            },
                            identity: {
                                question:
                                    "Pouvez-vous me communiquer vos noms et prénoms s'il vous plaît ?",
                            },
                            date: {
                                question:
                                    "A quel moment seriez vous disponible pour être recontacté ?",
                            },
                        },
                        rules: {
                            "<light>": ["<text>|reason <text>|identity <text>|date"],
                        },
                        toPlay: {
                            join: {},
                            start: {},
                            end: {},
                        },
                        onStart: { intent: "light" },
                    },
                }

                return rcsdk.createAgent(agentData).then((agent) => {

                    dispatch({ type: SET_SIGNUP_AGENT, agent: agent });
                    console.log(agent, "agent");
                    
                    const gdprSentences = {
                        "gdprConsentWelcome": appIntl().formatMessage(GDPRSentencesDefault["gdprConsentWelcome"]),
                        "gdprConsentAccept": appIntl().formatMessage(GDPRSentencesDefault["gdprConsentAccept"]),
                        "gdprConsentDecline": appIntl().formatMessage(GDPRSentencesDefault["gdprConsentDecline"]),
                        "gdprSmsWelcome": appIntl().formatMessage(GDPRSentencesDefault["gdprSmsWelcome"]),
                        "gdprSmsAccept": appIntl().formatMessage(GDPRSentencesDefault["gdprSmsAccept"]),
                        "gdprSmsDecline": appIntl().formatMessage(GDPRSentencesDefault["gdprSmsDecline"])
                    }

                    let formattedChannel = {
                        data: {
                            "live": true,
                            "escalation": true
                        },
                        agentId: agent.id,
                        companyId: company.id,
                        gdprSentences: gdprSentences,
                        name: "Phone",
                        type: "phone",
                        tokenHelpSelector: "phone-token"
                    };

                    return generateNumber(company.name).then((token) => {
                        console.log(formattedChannel, "formattedChannel");
                        console.log(token, "token");

                        return rcsdk.createChannel({ ...formattedChannel, token: token }).then(() => {
                            dispatch({ type: CREATE_COMPANY, status: "success", company: company });
                            return company;
                        }).catch(err => console.log(err, "err createChannel"))
                    }).catch(err => console.log(err, "err generateNumber"))
                })
            })
            .catch(err => {
                console.log(err, "err");
            })
    }
}